<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      height: 350,
      gradient: null,
      gradient2: null,
      attribute: null
    }
  },
  options: {
    legend: {
      Position: 'left',
      display: true,
      labels: {
        fontColor: 'rgb(255, 99, 132)'
      }
    }
  },
  watch: {
    chartData2: function (newData) {
      this.chartData2 = newData
    },
    chartData: function (newData) {
      this.chartData = newData
      this.renderChart(this.chartData, this.chartData2)
      this.renderChart(
        {
          height: '400',
          labels: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'Jul',
            'August',
            'September',
            'October',
            'November',
            'December'
          ],
          datasets: [
            {
              label: this.chartData[0].attribute,
              borderColor: 'rgba(132, 117, 78, 0.5)',
              pointBackgroundColor: 'white',
              borderWidth: 1,
              pointBorderColor: 'white',
              backgroundColor: this.gradient,
              data: this.chartData[0].data
            },
            {
              label: this.chartData2.label,
              borderColor: '#666666',
              pointBackgroundColor: 'white',
              pointBorderColor: 'white',
              borderWidth: 1,
              backgroundColor: this.gradient2,
              data: this.chartData2.values
            }
          ]
        },
        { responsive: true, maintainAspectRatio: false },
        this.options
      )
    }
  },
  props: {
    chartData: null,
    chartData2: null
  },
  mounted () {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient2 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient.addColorStop(0, 'rgba(132, 117, 78, 0.7)')
    this.gradient.addColorStop(0.5, 'rgba(132, 117, 78, 0.25)')
    this.gradient.addColorStop(1, 'rgba(132, 117, 78, 0)')

    this.gradient2.addColorStop(0, 'rgba(102, 102, 102, 0.9)')
    this.gradient2.addColorStop(0.5, 'rgba(102, 102, 102, 0.5)')
    this.gradient2.addColorStop(1, 'rgba(102, 102, 101, 0)')
  }
}
</script>
